
export const WaterAccessRasterVariables: string[] = [
    "Volta Subbasins(sh)"
]

export const AquiferSystemsRasterVariables: string[] = [
    "Piezometry(sh)"
]

export const DamsRasterVariables: string[] = [
    "Africa Dams(sh)",
    "Ke Hydro Power Dams(sh)",
    "Ke Micro-Hydro(sh)",
    "Ke Micro-Hydro Proposed(sh)",
    "Ke Mombasa Dams(sh)",
    "Ke Nairobi Dams(sh)"
]

export const DemandRasterVariables: string[] = []

export const EcosystemsRasterVariables: string[] = [
    "Hydrography(sh)",
    "Wet Area(sh)",
    "Water Bodies(sh)"
]

export const LakesRasterVariables: string[] = [
    "Africa Water Bodies(sh)",
    "Tana and Volta Basin(sh)",
    "Volta Basin Main Rivers(sh)",
    "Volta Subbasins(sh)",
]

export const SourcesRasterVariables: string[] = []

export const WaterConsumptionRasterVariables: string[] = [
    "Ke Water Consumption(sh)"
]

export const WatershedsRasterVariables: string[] = [
    "River Basins(sh)",
    "Ke Water Basins(sh)"
]