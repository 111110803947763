import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Sidebar.css';

// const baseurl = window.location.origin
const baseurl = "http://localhost:8000"

const CustomSidebar = () => {
    const [user, setUser] = useState("");
    const [login, setLogin] = useState(false)

    useEffect(() => {
        const email = localStorage.getItem('email')
        if (email) {
            setLogin(true);
            setUser(email);
        }
    }, [])

    const logout = () => {
        const email = localStorage.getItem('email')
        localStorage.clear()
        axios.post(`${baseurl}/api/logout/`, { email })
            .then(res => {
            })
            .catch(err => alert("oops, somethings went wrong."))
    }

    return (
        <>
            <nav className="mb-2 navbar navbar-dark navbar-theme-primary px-4 col-12 d-lg-none">
                <a className="navbar-brand me-lg-5" href="/">
                    <img className="navbar-brand-dark" src="assets/images/logoWhite.png" alt="EPIC logo" />
                    <img className="navbar-brand-light" src="assets/images/logoWhite.png" alt="EPIC logo" />
                </a>
                <div className="d-flex align-items-center">
                    <button className="navbar-toggler d-lg-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </nav>
            <nav id="sidebarMenu" className="sidebar d-lg-none bg-gray-800 text-white collapse" data-simplebar>
                <div className="sidebar-inner px-4 pt-1">
                    <div className="user-card d-lg-none">
                        <div className="mt-5 d-flex align-items-center justify-content-between justify-content-md-center pb-2">
                            <div className="d-flex align-items-center">
                                <a href="/" className="nav-link d-flex align-items-center">
                                    <span className="sidebar-icon m-0">
                                        <img src="assets/images/logoWhite.png" alt="EPIC logo" height="50" width="50" />
                                    </span>
                                </a>
                                {login ? (<div className="d-block">
                                    <h2 className="h4 mb-2">Hi, {user}</h2>
                                    <a className="p-0 fs-6 dropdown-item d-flex align-items-center" href={window.location.href} onClick={() => { logout() }}>
                                        <svg className="dropdown-icon text-danger me-0" height="20" width="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                        Logout
                                    </a>
                                </div>) : <h2 style={{ marginBottom: '0' }}>Welcome!</h2>}
                            </div>
                            <div className="collapse-close d-md-none" style={{ border: '1px solid white' }}>
                                <a href="#sidebarMenu" data-bs-toggle="collapse" className="d-flex"
                                    data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="true"
                                    aria-label="Toggle navigation">
                                    <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                            </div>
                        </div>
                        <ul className="nav flex-column pb-3 pt-md-0">
                            <li className="nav-item">
                                <Link to="/sectors" className="nav-link d-flex align-items-center">
                                    Sectors &nbsp;<svg xmlns="http://www.w3.org/2000/svg" height="16" width="8" viewBox="0 0 256 512"><path fill="white" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" /></svg>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className="nav-link d-flex align-items-center">
                                    About &nbsp;<svg xmlns="http://www.w3.org/2000/svg" height="16" width="8" viewBox="0 0 256 512"><path fill="white" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" /></svg>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/partners" className="nav-link d-flex align-items-center">
                                    Partners &nbsp;<svg xmlns="http://www.w3.org/2000/svg" height="16" width="8" viewBox="0 0 256 512"><path fill="white" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" /></svg>
                                </Link>
                            </li>
                            {!login ? (
                                <li className="nav-item">
                                    <Link to="/login" className="nav-link d-flex align-items-center">
                                        Login &nbsp;<svg xmlns="http://www.w3.org/2000/svg" height="16" width="8" viewBox="0 0 256 512"><path fill="white" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" /></svg>
                                    </Link>
                                </li>
                            ) : (null)}
                        </ul>
                    </div>
                </div>
            </nav >
        </>
    )
}

export default CustomSidebar;