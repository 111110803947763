import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const baseurl = window.location.origin

export const VerifyUser = () => {
    const [id, setId] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let url = window.location.href
        const lastChar = url[url.length - 1]
        if (lastChar === '/')
            url = window.location.href.slice(0, -1)
        const splittedURL = url.split('/')
        setId(splittedURL[splittedURL.length - 1])
    }, [])

    const checkUser = () => {
        setLoading(true)
        axios.post(`${baseurl}/api/verify/`, { 'id': id })
            .then(res => {
                if (res.data['success']) {
                    setSuccess(res.data['success'])
                } else {
                    setError(res.data['error'])
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                alert("Something went wrong")
            })
    }


    return (
        <>
            <main>
                <section className="vh-100 d-flex align-items-center justify-content-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-10 text-center d-flex align-items-center justify-content-center">
                                {loading ? (
                                    <div className="card card-body w-50 align-items-center">
                                        <span>
                                            <svg className="icon icon-s" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" /></svg>
                                        </span>
                                        <p>Loading...</p>
                                    </div>
                                ) : success ? (
                                    <div className="card card-body w-50 align-items-center">
                                        <p>Your email has been Verified!, Click the link below to login</p>
                                        <Link to="/login" className="btn btn-success w-25">Login</Link>
                                    </div>
                                ) : error ? (
                                    <div className="card card-body w-50 align-items-center">
                                        <p>{error}</p>
                                        <Link to="/signup" className="btn btn-danger w-25">Signup again</Link>
                                    </div>
                                ) : (
                                    <div className="card card-body w-50 align-items-center">
                                        <p>Click the button below to verify</p>
                                        <button className="btn btn-primary w-25" onClick={() => { checkUser() }}>Verify</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}