import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface DownloadProps {
    startDate?: Date | null
    endDate?: Date | null
    theType?: String
}

const baseurl = window.location.origin

export const ExportButton = (props: DownloadProps) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const downloadRaster = async () => {
        const email = localStorage.getItem('email')
        if (email) {
            axios.post(`${baseurl}/api/check/`, { email })
                .then(res => {
                    if (!res.data['status']) {
                        alert("Login first to export data.")
                        navigate('/login')
                        return
                    }
                })
                .catch(err => alert('oops, something went wrong'))
        } else {
            alert("Login first to export data.")
            navigate('/login')
            return
        }
        let url = window.location.href
        const lastChar = url[url.length - 1]

        if (lastChar === '/')
            url = window.location.href.slice(0, -1)

        const splittedURL = url.split('/')
        const store_name = splittedURL[splittedURL.length - 1].replaceAll('_', ' ')

        const storeName = sessionStorage.getItem(store_name)
        const getlayers = sessionStorage.getItem(`${storeName}_layers`)
        if (getlayers !== null) {
            const parsedlayers = JSON.parse(getlayers)
            const layers = parsedlayers.map((item: any) => {
                return item[2]
            })
            setLoading(true)
            axios.post(`${baseurl}/api/raster-download/`, { layers: layers })
                .then(res => {
                    const aTag = document.createElement('a');
                    aTag.href = `data:application/zip;base64, ${res.data['file']}`
                    aTag.setAttribute('download', `${storeName}.zip`);
                    document.body.appendChild(aTag);
                    aTag.click();
                    document.body.removeChild(aTag);
                    setLoading(false)
                    return
                })
                .catch(err => {
                    alert("Failed to export data, try again");
                    setLoading(false)
                })
        }
        return
    }

    async function handleDownload(cls: string, locName: string) {
        if (document.getElementById('errorDiv')) {
            document.getElementById('errorDiv')?.remove()
        };

        if (props.theType === 'raster') {
            downloadRaster()
            return
        }

        if (props.startDate === null || props.endDate === null) {
            alert("Select Time Range");
            return
        }

        const start = new Date(props.startDate!).toISOString().split('T')[0]
        const end = new Date(props.endDate!).toISOString().split('T')[0]

        if (props.startDate! > props.endDate!) {
            alert("Starting date should not be more than ending date");
            return
        }

        const varList: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName(cls) as HTMLCollectionOf<HTMLInputElement>;
        const varArray: Array<any> = []
        Array.from(varList).forEach((item) => {
            if (item.checked) {
                varArray.push(item.name)
            }
        })

        if (varArray.length === 0) {
            alert("No variable selected!")
            return
        }

        const locationList: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName(locName) as HTMLCollectionOf<HTMLInputElement>;
        const locationArray: Array<any> = []
        Array.from(locationList).forEach((item) => {
            if (item.checked) {
                locationArray.push(item.name)
            }
        })

        if (locationArray.length === 0) {
            alert("No location selected!")
            return
        }

        const email = localStorage.getItem('email')
        if (email) {
            axios.post(`${baseurl}/api/check/`, { email })
                .then(res => {
                    if (!res.data['status']) {
                        alert("Login first to export data.")
                        navigate('/login')
                        return
                    }
                })
                .catch(err => alert('oops, something went wrong'))
        } else {
            alert("Login first to export data.")
            navigate('/login')
            return
        }

        setLoading(true)

        axios.post(`${baseurl}/api/timeseries-download/`, {
            start, end, variables: varArray, locations: locationArray
        })
            .then(res => {
                if (res.data['info']) {
                    setLoading(false)
                    alert(res.data['info'])
                    return
                }
                const aTag = document.createElement('a');
                aTag.href = `data:application/zip;base64, ${res.data['file']}`
                aTag.setAttribute('download', 'CLEWS_portal_timeseries.zip');
                document.body.appendChild(aTag);
                aTag.click();
                document.body.removeChild(aTag);
                setLoading(false)
                return
            })
            .catch(error => {
                alert("Failed to export data, try again")
                setLoading(false)
            })
    }

    return (
        <>
            {loading ? (
                <button type="button" className="btn btn-sm btn-info d-inline-flex align-items-center disabled" style={{ opacity: 1 }}>
                    <small className="spinner-border ms-auto me-2" style={{ width: "20px", height: "20px" }} role="status" aria-hidden="true"></small>
                    <strong className="fw-bold">Exporting...</strong>
                </button>
            ) : (
                <button type="button" onClick={() => { handleDownload('varName', 'locName') }} className="btn btn-sm btn-secondary d-inline-flex align-items-center">
                    <svg className="icon icon-xs me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                    Export
                </button>
            )}
        </>
    )
}