import axios from "axios";
import { useState } from "react";

interface PreviewProps {
    startDate?: Date | null
    endDate?: Date | null
    datafn: (nestedArray: string[][]) => void;
}

const baseurl = window.location.origin

export const PreviewButton = (props: PreviewProps) => {
    const [loading, setLoading] = useState(false)

    async function handlePreview(cls: string, locName: string) {

        if (props.startDate === null || props.endDate === null) {
            alert("Select Time Range");
            return
        }

        const start = new Date(props.startDate!).toISOString().split('T')[0]
        const end = new Date(props.endDate!).toISOString().split('T')[0]

        if (props.startDate! > props.endDate!) {
            alert("Starting date should not be more than ending date");
            return
        }

        setLoading(true)

        const varList: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName(cls) as HTMLCollectionOf<HTMLInputElement>;
        const varArray: Array<any> = []
        Array.from(varList).forEach((item) => {
            if (item.checked) {
                varArray.push(item.name)
            }
        })

        if (varArray.length === 0) {
            alert("No variable selected!")
            return
        }

        const locationList: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName(locName) as HTMLCollectionOf<HTMLInputElement>;
        const locationArray: Array<any> = []
        Array.from(locationList).forEach((item) => {
            if (item.checked) {
                locationArray.push(item.name)
            }
        })

        if (locationArray.length === 0) {
            alert("No location selected!")
            setLoading(false)
            return
        }

        axios.post(`${baseurl}/api/get-data/`, {
            start, end, variables: varArray, locations: locationArray
        })
            .then((response) => {
                if (response.data['info']) {
                    setLoading(false)
                    alert(response.data['info'])
                    return
                }
                const data = response.data['data']
                props.datafn(data)
                setLoading(false)
                return
            })
            .catch(error => {
                alert('Encountered error when previewing')
                setLoading(false)
                return
            })
    }

    return (
        <>
            <button type="button" onClick={() => { handlePreview('varName', 'locName') }} className={`btn btn-sm btn-info me-3 d-inline-flex align-items-center ${loading ? 'disabled' : ''}`} style={{ opacity: 1 }}>
                {loading ? <>
                    <small className="spinner-border ms-auto me-2" style={{ width: "20px", height: "20px" }} role="status" aria-hidden="true"></small>
                    <strong className="fw-bold">loading preview content...</strong>
                </> : <>
                    <svg className="icon icon-xs me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" /></svg>
                    Preview
                </>}
            </button>
        </>
    )
}