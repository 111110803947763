import axios from "axios";
import { useEffect, useState } from "react";
import { ImageOverlay, LayersControl, MapContainer, TileLayer } from "react-leaflet";
import { ExportButton } from "../ExportButton/ExportButton";
import "./TR_Custom.css";

interface RasterProps {
    varList: (string | null)[]
    description?: string
    attribution?: string
}

const baseurl = window.location.origin

export const RasterLayout = () => {
    const [imageLayers, setImageLayers] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
        let url = window.location.href
        const lastChar = url[url.length - 1]

        if (lastChar === '/')
            url = window.location.href.slice(0, -1)

        const splittedURL = url.split('/')
        const store_name = splittedURL[splittedURL.length - 1].replaceAll('_', ' ')

        const storeName = sessionStorage.getItem(store_name)
        if (storeName)
            getWMSLayers(storeName)

    }, [])

    const getWMSLayers = (storeName: string) => {
        axios.get(`${baseurl}/api/get-wms?store=${storeName}`)
            .then(res => res.data)
            .then(data => {
                if (data.status === "success") {
                    sessionStorage.setItem(`${storeName}_layers`, JSON.stringify(data.data))
                    setImageLayers(data.data)
                } else {
                    alert('Could not retrieve data, try again later.')
                }
                setLoading(false)
                return
            })
            .catch(err => alert("oops, something went wrong."))
    }


    return (
        <>
            <div className="col-12 px-2 d-flex justify-content-center" id="alert-noti">
                <div className="col-12 mb-4">
                    <div className="card notification-card border-0 shadow">
                        <div className="card-header d-flex align-items-center">
                            <div className="col-md-4 pe-3">
                                <h2 className="fs-5 fw-bold mb-0">Raster Data</h2>
                            </div>
                            <div className="col-md-8 ps-1">
                                <ExportButton theType={'raster'} />
                            </div>
                        </div>
                        {loading ? <div className="d-flex align-items-center p-2 px-4">
                            <strong>Map layer(s) loading...</strong>
                            <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                        </div> : <div className="d-flex align-items-center p-2 px-4">
                            <p className="small text-info m-0">Hover on the layer icon on the top-right corner of the map to display the layers and select.</p>
                        </div>
                        }
                    </div>
                </div>
            </div>

            {/* visualizing */}
            <div className="col-12">
                {imageLayers.length === 0 ? (<div className="row">
                    <div className="col-12">
                        <MapContainer
                            center={[9.1021, 18.2812]}
                            zoom={4}
                            scrollWheelZoom={false}
                        >
                            <TileLayer
                                attribution='&copy;
                                <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                        </MapContainer>
                    </div>
                </div>) : (
                    <div className="card card-body shadow border-0 p-2 mb-3">
                        <div className="row">
                            <div className="col-12">
                                <MapContainer
                                    center={[9.1021, 18.2812]}
                                    zoom={4}
                                    scrollWheelZoom={true}
                                >
                                    <TileLayer
                                        attribution='&copy;
                                            <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <LayersControl position="topright">
                                        {imageLayers.map((layer, ind) => {
                                            const url = `data:image /png;base64,${layer[0]}`
                                            return (
                                                <LayersControl.Overlay key={layer[2] + ind} name={layer[2]}>
                                                    <ImageOverlay
                                                        url={url}
                                                        bounds={layer[1]}
                                                        opacity={1.0}
                                                    />
                                                </LayersControl.Overlay>
                                            )
                                        })}
                                    </LayersControl>
                                </MapContainer>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
