import Footer from "../../../../components/Footer/Footer";
import Navbar from "../../../../components/Navbar/Navbar";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import { RasterLayout } from "../../../../components/TimeseriesRasterExternalLayout/RasterLayout";
import { EnergyAccessRasterVariables } from "../../../../utils/Raster/Variables/Energy/EnergyRasterVariables";

export const EnergyAccessRaster = () => {

    return (
        <main className="content p-0">
            <DataTypeSidebar
                timeseriesURL="/sectors/energy/access/timeseries"
                rasterURL="/sectors/energy/access/raster"
                externalURL="/sectors/energy/access/external"
                sectorName="Energy"
                type="raster"
                varList={EnergyAccessRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Energy Sector - Access</h2>
                    <p className="lead px-lg-10">Rasta Data for energy sector on energy access can be found here.</p>
                </div>
            </div>
            <div className="row p-3">
                <RasterLayout />
            </div>
            <Footer />
        </main>
    )
}