import Footer from "../../../../components/Footer/Footer";
import Navbar from "../../../../components/Navbar/Navbar";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import { RasterLayout } from "../../../../components/TimeseriesRasterExternalLayout/RasterLayout";
import { WeatherForecastRasterVariables } from "../../../../utils/Raster/Variables/Climate/ClimateRasterVariables";

export const WeatherForecastRaster = () => {

    return (
        <main className="content px-0">
            <DataTypeSidebar
                timeseriesURL="/sectors/climate/weather-observations/timeseries"
                rasterURL="/sectors/climate/weather-observations/raster"
                externalURL="/sectors/climate/weather-observations/external"
                sectorName="Climate"
                type="raster"
                varList={WeatherForecastRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Climate Sector - Weather Observation</h2>
                    <p className="lead px-lg-10">Rasta Data for Climate sector on Weather Observations can be found here.</p>
                </div>
            </div>

            <div className="row p-3">
                <RasterLayout />
            </div>
            <Footer />
        </main>
    )
}