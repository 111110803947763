import { Route, Routes } from 'react-router-dom';
import NotFound from '../../../pages/404Page/404page';
import { CarbonExternal } from '../../../pages/Sectors/Agriculture/Carbon/CarbonExternal';
import { CarbonRaster } from '../../../pages/Sectors/Agriculture/Carbon/CarbonRaster';
import { CarbonTimeseries } from '../../../pages/Sectors/Agriculture/Carbon/CarbonTimeseseries';
import { ConsumptionExternal } from '../../../pages/Sectors/Agriculture/Consumption/ConsumptionExternal';
import { ConsumptionRaster } from '../../../pages/Sectors/Agriculture/Consumption/ConsumptionRaster';
import { ConsumptionTimeseries } from '../../../pages/Sectors/Agriculture/Consumption/ConsumptionTimeseries';
import { LandExternal } from '../../../pages/Sectors/Agriculture/Land/LandExternal';
import { LandRaster } from '../../../pages/Sectors/Agriculture/Land/LandRaster';
import { LandTimeseries } from '../../../pages/Sectors/Agriculture/Land/LandTimeseries';
import { OtherEmissionsExternal } from '../../../pages/Sectors/Agriculture/OtherEmissions/OtherEmissionsExternal';
import { OtherEmissionsRaster } from '../../../pages/Sectors/Agriculture/OtherEmissions/OtherEmissionsRaster';
import { OtherEmissionsTimeseries } from '../../../pages/Sectors/Agriculture/OtherEmissions/OtherEmissionsTimeseries';
import { PricesExternal } from '../../../pages/Sectors/Agriculture/Prices/PricesExternal';
import { PricesRaster } from '../../../pages/Sectors/Agriculture/Prices/PricesRasta';
import { PricesTimeseries } from '../../../pages/Sectors/Agriculture/Prices/PricesTimeseries';
import { ProductionExternal } from '../../../pages/Sectors/Agriculture/Production/ProductionExternal';
import { ProductionRaster } from '../../../pages/Sectors/Agriculture/Production/ProductionRaster';
import { ProductionTimeseries } from '../../../pages/Sectors/Agriculture/Production/ProductionTimeseries';
import { SoilExternal } from '../../../pages/Sectors/Agriculture/Soil/SoilExternal';
import { SoilRaster } from '../../../pages/Sectors/Agriculture/Soil/SoilRaster';
import { SoilTimeseries } from '../../../pages/Sectors/Agriculture/Soil/SoilTimeseries';
import { AgricultureOverview } from '../../Overview/AgricultureOverview';

const AgricultureRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<AgricultureOverview />} />
            <Route path="production/timeseries" element={<ProductionTimeseries />} />
            <Route path="production/raster" element={<ProductionRaster />} />
            <Route path="production/raster/:name" element={<ProductionRaster />} />
            <Route path="production/external" element={<ProductionExternal />} />
            <Route path="consumption/timeseries" element={<ConsumptionTimeseries />} />
            <Route path="consumption/raster" element={<ConsumptionRaster />} />
            <Route path="consumption/raster/:name" element={<ConsumptionRaster />} />
            <Route path="consumption/external" element={<ConsumptionExternal />} />
            <Route path="prices/timeseries" element={<PricesTimeseries />} />
            <Route path="prices/raster" element={<PricesRaster />} />
            <Route path="prices/raster/:name" element={<PricesRaster />} />
            <Route path="prices/external" element={<PricesExternal />} />
            <Route path="land/timeseries" element={<LandTimeseries />} />
            <Route path="land/raster" element={<LandRaster />} />
            <Route path="land/raster/:name" element={<LandRaster />} />
            <Route path="land/external" element={<LandExternal />} />
            <Route path="carbon/timeseries" element={<CarbonTimeseries />} />
            <Route path="carbon/raster" element={<CarbonRaster />} />
            <Route path="carbon/raster/:name" element={<CarbonRaster />} />
            <Route path="carbon/external" element={<CarbonExternal />} />
            <Route path="soil/timeseries" element={<SoilTimeseries />} />
            <Route path="soil/raster" element={<SoilRaster />} />
            <Route path="soil/raster/:name" element={<SoilRaster />} />
            <Route path="soil/external" element={<SoilExternal />} />
            <Route path="other-emissions/timeseries" element={<OtherEmissionsTimeseries />} />
            <Route path="other-emissions/raster" element={<OtherEmissionsRaster />} />
            <Route path="other-emissions/raster/:name" element={<OtherEmissionsRaster />} />
            <Route path="other-emissions/external" element={<OtherEmissionsExternal />} />
            <Route path="*" element={< NotFound />} />
        </Routes>
    );
}

export default AgricultureRoutes;
